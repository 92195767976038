<template>
  <div>
    <!-- 储能产品 PC 版 -->
    <div v-if="!isMobile">
      <div v-for="(item, index) in videoList" :key="index">
        <videoindex
          :item="item"
          :ref="'deo' + index"
          :isones="index == 0 ? true : false"
          @video_runpage="video_runpage"
        ></videoindex>
      </div>
    </div>

    <!-- 储能产品手机版 -->
    <div v-if="isMobile">
      <div v-for="(item, index) in videoList" :key="index">
        <mobilenewtest
          :id="'ads' + index"
          :item="item"
          :ref="'deo' + index"
          :isones="index == 0 ? true : false"
          @video_runpage="video_runpage"
        ></mobilenewtest>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import p1 from "@/assets/images/pcsbox/p1.jpg";
import p2 from "@/assets/images/pcsbox/p2.png";
import p3 from "@/assets/images/pcsbox/p3.png";
import p4 from "@/assets/images/pcsbox/p4.jpg";
// 手机版
import mobilenewtest from "@/components/mobile/mobilenewtest";
import mp1 from "@/assets/images/pcsbox/mp1.jpg";
import mp2 from "@/assets/images/pcsbox/mp2.jpg";
import mp3 from "@/assets/images/pcsbox/mp3.jpg";
import mp4 from "@/assets/images/pcsbox/mp4.jpg";

import videoindex from "@/components/pc/videoindex";
import { mixins } from "@/common/scrollmixin.js";
import { look_mixin } from "@/utils/looktime.js";
export default {
  components: { videoindex, mobilenewtest },
  computed: {
    ...mapGetters(["isMobile"]),
  },
  mixins: [mixins, look_mixin],
  mounted() {
    //组件挂载完毕后初始化Swiper
    if (this.isMobile) {
      this.videoList[0].src = mp1;
      this.videoList[1].src = mp4;
      this.videoList[2].src = mp2;
      this.videoList[3].src = mp3;
    }
    window.addEventListener("scroll", this.handleScroll);
    setTimeout(() => {
      this.videoList[0].pages = true;
      this.videoList[1].pages = true;
      this.videoList[2].pages = true;
    }, 500);
  },
  data() {
    return {
      videoList: [
        {
          id: 1,
          title: "Z BOX-C 液冷储能系统",
          title5: "Z BOX-C",
          title6: "液冷储能系统",
          tofidetitle: true, // 是否否定主标题
          desc: "模块化工商业储能",
          src: p1,
          isvideo: false,
          pages: true,
          runurl: `/pcsboxdetial/${1}`,
        },
        {
          id: 4,
          title: "Z BOX-H液冷储能系统",
          title6: "液冷储能系统",
          title5: "CityBox-H",
          tofidetitle: true, // 是否否定主标题
          desc: "模块化多用途储能",
          src: p4,
          isvideo: false,
          pages: true,
          runurl: `/pcsboxdetial/${2}`,
        },
        {
          id: 2,
          title: "CityBox阵列",
          title6: "",
          title5: "CityBox阵列",
          tofidetitle: true, // 是否否定主标题
          desc: "大容量储能电站",
          src: p2,
          isvideo: false,
          pages: false,
          runurl: `/pcsboxdetial/${3}`,
        },
        {
          id: 3,
          title: "ZOE-PW100-L-A",
          title5: "ZOE-PW100-L-A",
          title6: "",
          tofidetitle: true, // 是否否定主标题
          desc: "双向组串式储能变流器",
          src: p3,
          isvideo: false,
          pages: false,
          runurl: `/pcsboxdetial/${4}`,
        },
      ],
    };
  },
  methods: {
    video_runpage(e) {
      this.user_btn_click(e.title, e.runurl, e.btn_name);
    },
    handleScroll() {
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      // 第一个屏幕
      if (this.scroll < this.positionList[1].top + 25) {
        this.videoList[0].pages = true;
        this.videoList[1].pages = false;
        this.videoList[2].pages = false;
        this.videoList[3].pages = false;
      }
      // 第二个屏幕
      if (
        this.scroll < this.positionList[2].top - 100 &&
        this.scroll > this.positionList[1].top - 100
      ) {
        this.videoList[0].pages = false;
        this.videoList[1].pages = true;
        this.videoList[2].pages = false;
        this.videoList[3].pages = false;
      }
      // 第三个屏幕
      if (
        this.scroll > this.positionList[2].top - 100 &&
        this.scroll < this.positionList[3].top - 100
      ) {
        this.videoList[0].pages = false;
        this.videoList[1].pages = false;
        this.videoList[2].pages = true;
        this.videoList[3].pages = false;
      }
      // 第四个屏幕
      if (this.scroll > this.positionList[3].top - 100) {
        this.videoList[0].pages = false;
        this.videoList[1].pages = false;
        this.videoList[2].pages = false;
        this.videoList[3].pages = true;
      }
    },
  },
};
</script>
<style >
.swiper-pagination-bullet-active {
  width: 20px;
  height: 8px;
  border-radius: 4px;
  display: inline-block;
  background: #006450;
  /* opacity: 0.2; */
}

.swiper-button-prev,
.swiper-button-next {
  color: #fff;
  padding: 40px 20px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.05);
}
</style>
<style lang="scss" scoped>
.mobgitem {
  background: #f7f8fa;
}
.tobg1 {
  background: #e0e1e3;
}
.tobg2 {
  background: #f2f3f5;
}
.cpingbox1 {
  width: 650px;
  height: 1240px;
  // background: #096d53;
}
.cpingbox {
  width: 650px;
  height: 620px;
  // background: #096d53;
}
.imgbox {
  width: 100%;
  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}
.w-50 {
  width: 50%;
}
.banner {
  width: 100%;
  height: 100%;
  position: relative;
  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 1);
    top: 0px;
    right: 0px;
    z-index: 1;
    opacity: 0.8;
  }
  .banner-titles {
    top: 450px;
    width: 100%;
    // letter-spacing: 5px;
  }
  .mobile-banner-more {
    margin-top: 50px !important;
    .mores {
      padding: 5px 10px;
      border-radius: 10px;
      border: 2px solid;
      box-sizing: border-box;
      cursor: pointer;
      &:hover {
        background-image: linear-gradient(to right, #6fb545, #3b904c, #096d53);
        // border-color: #006450 !important;
        border: none;
        padding: 9px 14px;
      }
    }
  }
  .banner-more {
    margin-top: 70px;
    .mores {
      padding: 5px 10px;
      border-radius: 10px;
      border: 2px solid;
      box-sizing: border-box;
      cursor: pointer;
      &:hover {
        background-image: linear-gradient(to right, #6fb545, #3b904c, #096d53);
        // border-color: #006450 !important;
        border: none;
        padding: 9px 14px;
      }
    }
  }
}

.line-h50 {
  line-height: 40px;
}
</style>